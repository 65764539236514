<template>
  <div class="page">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div class="list">
        <div class="item" v-for="(item, index) in listData" :key="index">
          <div class="item-top">
            <img :src="item.photo" alt="" />
          </div>
          <div class="item-bottom">
            {{ item.introduce }}
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { tsIntroductionList } from "./api.js";
export default {
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      type: null,
    };
  },
  created() {
    const { type } = this.$route.query;
    this.type = type;
  },
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        type: this.type,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios
        .get(`${tsIntroductionList}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
            this.listData.forEach((ele) => {
              if (ele.photo) {
                ele.photo = this.$handleImg(336, 280, ele.photo);
              }
            });
          }
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px;
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 60px;
    .item {
      width: 336px;
      min-height: 508px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 20px;
      .item-bottom {
        box-sizing: border-box;
        height: calc(100% - 280px);
        padding: 16px 20px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      .item-top {
        width: 100%;
        height: 280px;
        background: #000;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>